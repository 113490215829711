import React, { Component } from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import cirrus from "../images/cirrus-logo.svg"
import icon1 from "../images/tachometer-alt-solid.svg"
import icon2 from "../images/mail-bulk-solid.svg"
import icon3 from "../images/sort-numeric-down-alt-solid.svg"
import icon4 from "../images/user-astronaut-solid.svg"
import "../css/cirrusPage.css"

class CirrusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <Layout>
        <SEO title="cirrus" />
        <div className="cirrus">
          <div className="header-wrap">
            <div className="content-wrap">
              <div className="title">
                AI 電子郵件自動分類與偵測服務
              </div>
              <div className="subTitle">高效率與高規格的電子資訊風險管理服務，減少可能性威脅與損失</div>
            </div>
          </div>
          <div className="slogan">
            <div className="txt">
              人工智慧電子郵件稽核系統<br />
              提供持續性且自動化的電子資訊控管
            </div>
          </div>
          <div className="features">
            <div className="section">
              <div className="title">
                <img src={cirrus} alt="cirrus"/>
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon1} alt=""/>
                  </div>
                  <div className="title">
                    總覽儀表板
                  </div>
                  <div className="txt">
                    提供部門 KPI 指數達成數據。提供數據報告已偵查到文件之安全性與危險性之比例與數據。
                  </div>
                </div>
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon2} alt=""/>
                  </div>
                  <div className="title">
                    電子郵件系統串接
                  </div>
                  <div className="txt">
                    串接需過濾與偵查之郵件信箱與部門設定。巨量電子資料庫儲存與分析。
                  </div>
                </div>
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon3} alt=""/>
                  </div>
                  <div className="title">
                    評比與確認
                  </div>
                  <div className="txt">
                    依照客戶企業要求，經巨量數據與資料為辨別依據後，Cirrus 人工智慧依照電子資料的危險性做分數評比，使內容主管可以依照分數評比再查核電子資料內容。
                  </div>
                </div>
              </div>
              <div className="features-item-wrap">
                <div className="features-item">
                  <div className="icon-wrap">
                    <img src={icon4} alt=""/>
                  </div>
                  <div className="title">
                    人工智慧
                  </div>
                  <div className="txt">
                    導入客戶企業大量數據與資料，運用人工智慧進行預測性編碼程式語言檢閱內部電子郵件 Predictive Coding 產生檢測性指標，並運用指標對於電子資訊可能違反企業內部法規則進行危險性分數排列。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CirrusPage;
